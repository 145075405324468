import React from "react";
import { graphql } from "gatsby";

import Meta from "components/Meta";
import Layout from "components/Layout";
import PostRenderComponents from "components/PostRenderComponents";

import { useBreadcrumbs } from "modules/breadcrumbs/hooks";
import * as Hero from "components/Hero";
import * as ResourceHubPost from "components/ResourceHubPost";
import { HeroColour, HeroSize } from "components/Hero/Banner";
import { AuthorProps } from "components/ResourceHubPost/PublishInfo";
import { ImageWithCaptionProps } from "components/ImageWithCaption";
import { TextWithSubheadingProps } from "components/TextWithSubheading";
import { ContentsHeadingProps } from "components/ContentsHeading";
import { DocDownloadProps } from "components/DocDownload";
import { DegreesBannerLargeProps } from "components/SixtyThreeDegreesBanner/Large";
import { DegreesBannerMediumProps } from "components/SixtyThreeDegreesBanner/Medium";
import { PageNavigationProps } from "components/PageNavigation";
import { DegreesBannerSmallProps } from "components/SixtyThreeDegreesBanner/Small";
import { StatProps } from "components/Stats/Stat";
import { TestimonialsProps } from "components/Testimonials";
import { useHeight } from "components/Header/hooks";
import { VideoWithCaptionProps } from "components/VideoWithCaption";
import { FullwidthImageAndTextProps } from "components/FullwidthImageAndText";
import { TableSectionProps } from "components/TableSection";
import { RelatedContentProps } from "components/RelatedContent";
import { TwoColumnImageProps } from "components/TwoColumnImage/types";
import { ImageHorizontalPosition } from "modules/images/constants";
import { AccordionProps } from "components/Accordion";
import { FindATrainerProps } from "components/FindATrainer";
import { useNewsletterPopup } from "components/Newsletter/hooks/newsletterPopup";
import { DefaultMetaType } from "modules/meta/types";
import { useArticleSchema, useArticleSchemaProps } from "modules/meta/schema/ArticleSchema";
import { NewsletterPopupType } from "modules/newsletter/types";

type NewsPageProps = { data: QueryTypes; location: any };

type QueryTypes = {
  contentstackNewsletterPopup: NewsletterPopupType;
  contentstackGlobalMeta: DefaultMetaType;
  contentstackNewsPage: {
    id: string;
    title: string;
    slug: string;
    news_meta: {
      window_title: string;
      meta_description: string;
      canonical?: string;
      opengraph_title?: string;
      opengraph_description?: string;
      opengraph_type?: string;
      opengraph_url?: string;
      opengraph_image?: {
        url: string;
      };
      publication_date: string;
      author: AuthorProps[];
    };
    newsletter: {
      show_popup_page: string;
    };
    images: {
      hero_image: {
        url: string;
      };
      hero_image_horizontal_position: ImageHorizontalPosition;
      featured_image: {
        url: string;
      };
    };
    intro_content: {
      intro_paragraph: string;
      display_table_of_contents?: boolean;
      table_of_contents_title?: string;
    };
    intro_content_widgets: {
      text: TextWithSubheadingProps;
      video_with_caption: VideoWithCaptionProps;
    };
    body_content_widgets: {
      contents_heading: ContentsHeadingProps;
      document_download: DocDownloadProps;
      documents_download: DocDownloadProps;
      image_with_caption: ImageWithCaptionProps;
      large_degrees_banner: DegreesBannerLargeProps;
      medium_degrees_banner: DegreesBannerMediumProps;
      page_navigation: PageNavigationProps;
      small_degrees_banner: DegreesBannerSmallProps;
      stats: StatProps;
      testimonials: TestimonialsProps;
      text_with_subeading: TextWithSubheadingProps;
      video_with_caption: VideoWithCaptionProps;
      fullwidth_image_and_text: FullwidthImageAndTextProps;
      table_section: TableSectionProps;
      accordion: AccordionProps;
      related_content: RelatedContentProps;
      twoColumnImage: TwoColumnImageProps;
      findATrainer: FindATrainerProps;
    };
  };
};

const NewsPage: React.FC<NewsPageProps> = ({ data, location }) => {
  /* Newsletter Popup */
  const newsletter = data?.contentstackNewsletterPopup;
  const page = data?.contentstackNewsPage;

  const { breadcrumbs } = useBreadcrumbs(location);
  const news = data?.contentstackNewsPage;

  const heroImage = news?.images?.hero_image?.url;
  const featuredImage = news?.images?.featured_image?.url;
  const heroBanner = heroImage ? heroImage : featuredImage ? featuredImage : null;

  const { height, ref } = useHeight();

  useNewsletterPopup({
    config: newsletter?.config,
    copy: newsletter?.copy,
    submitted: newsletter?.submitted,
    pageOverride: page?.newsletter?.show_popup_page,
  });

  const articleSchema: useArticleSchemaProps = {
    location,
    headline: news.title,
    image: heroBanner,
    description: news?.intro_content?.intro_paragraph,
    author: news?.news_meta?.author[0]?.title,
    datePublished: news?.news_meta?.publication_date,
    timeRequired: null,
  };

  return (
    <Layout forwardedRef={ref}>
      <Meta
        defaultMeta={data.contentstackGlobalMeta}
        location={location}
        meta={news.news_meta}
        schemaMarkup={useArticleSchema({ ...articleSchema })}
      />
      <Hero.Banner
        background_colour={HeroColour.MONOCHROME_5}
        content={`<h1>${news?.title}</h1>`}
        crumbs={breadcrumbs}
        hero_breadcrumbs={true}
        hero_image={news?.images?.hero_image ? news?.images?.hero_image : news?.images?.featured_image}
        hero_size={HeroSize.SMALL}
        image_horizontal_position={news?.images?.hero_image_horizontal_position}
        slogan_title={false}
        title={news.title ? news.title : ""}
      />
      <ResourceHubPost.Wrapper>
        <div className="container small"></div>
        <div className="container small">
          <div>
            <ResourceHubPost.PublishInfo author={news?.news_meta?.author[0]} date={news?.news_meta?.publication_date} />
          </div>
          <div>
            <ResourceHubPost.Intro
              intro={news?.intro_content?.intro_paragraph}
              headerHeight={height}
              showTableOfContents={news?.intro_content?.display_table_of_contents}
              tableOfContentsTitle={news?.intro_content?.table_of_contents_title}
            />
            {news?.intro_content_widgets && (
              <PostRenderComponents components={news?.intro_content_widgets} noContainer={true} />
            )}
          </div>
        </div>
        {news?.body_content_widgets && <PostRenderComponents components={news?.body_content_widgets} />}
      </ResourceHubPost.Wrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($id: String) {
    contentstackNewsletterPopup {
      ...newsletterPopup
    }
    contentstackGlobalMeta {
      ...defaultMeta
    }
    contentstackNewsPage(id: { eq: $id }) {
      id
      title
      excerpt
      news_meta {
        window_title
        meta_description
        canonical
        opengraph_title
        opengraph_description
        opengraph_type
        opengraph_url
        opengraph_image {
          url
        }
        publication_date
        author {
          bio
          title
          image {
            url
          }
        }
      }
      newsletter {
        show_popup_page
      }
      images {
        hero_image {
          url
          title
        }
        hero_image_horizontal_position
        featured_image {
          url
          title
        }
      }
      intro_content {
        intro_paragraph
        display_table_of_contents
        table_of_contents_title
      }
      intro_content_widgets {
        ...videoWithCaptionNewsIntro
        ...textNews
      }
      body_content_widgets {
        ...contentHeadingsNewsBody
        ...imageWithCaptionNewsBody
        ...videoWithCaptionNewsBody
        ...tableSectionNewsBody
        ...textWithSubheadingNews
        ...testimonialsSectionNews
        ...degreesBannerSmallNews
        ...degreesBannerMediumNews
        ...degreesBannerLargeNews
        ...documentDownloadNews
        ...documentsDownloadNews
        ...fullwidthImageAndTextNews
        ...accordionNews
        ...relatedContentNews
        ...statsNews
        ...twoColumnImageNews
        ...findATrainerNews
      }
    }
  }
`;

export default NewsPage;
